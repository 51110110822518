import * as React from 'react';

import DownArrow from '../../assets/images/down.svg';

import { services } from './data';

import {
  ProductPanelContainer,
  ProductPanel,
  ProductPiture,
  ProductText,
  ProductTextContainer,
  ProductTitle,
  DMEProduct,
  DMEProductImage,
  DMEProductTextContainer,
  DMEProductTitle,
  IconsSection,
  DropDownArrow,
} from './styles';

export const Products = () => {
  const [openProductSection, setOpenProductSection] = React.useState('');

  const handleProductClick = (productType: React.SetStateAction<string>) => {
    if (openProductSection === productType) {
      return setOpenProductSection('');
    }
    return setOpenProductSection(productType);
  };

  return (
    <>
      {services.map((item: any) => {
        return (
          <ProductPanelContainer>
            <ProductPanel>
              <ProductTextContainer
                onClick={() => handleProductClick(item.typeOfProduct)}
                gridPosition={item.typeOfProduct}
              >
                <ProductTitle>
                  {item.typeOfProduct}
                  <DropDownArrow
                    isActive={openProductSection === item.typeOfProduct}
                    src={DownArrow}
                  />
                </ProductTitle>
                {item?.description && <ProductText>{item.description}</ProductText>}
                <IconsSection
                  isActive={openProductSection === item.typeOfProduct}
                  numberProducts={item.products.length.toString()}
                >
                  {item.products.map((product: any) => {
                    return (
                      <DMEProduct key={product.title}>
                        <DMEProductImage bgImage={product.img} />
                        <DMEProductTextContainer>
                          <DMEProductTitle longText={product.title.includes('(')}>
                            {product.title}
                          </DMEProductTitle>
                        </DMEProductTextContainer>
                      </DMEProduct>
                    );
                  })}
                </IconsSection>
              </ProductTextContainer>
              <ProductPiture gridPosition={item.typeOfProduct}>
                <source srcSet={item.imgAvif} type="image/avif" />
                <img srcSet={item.imgPNG} alt={item.alternativeText} />
              </ProductPiture>
            </ProductPanel>
          </ProductPanelContainer>
        );
      })}
    </>
  );
};

export default Products;
