import * as React from 'react';

import Layout from '@components/layout';
import Seoinfo from '@components/seoinfo/seoinfo';
import { InternalWrapper } from '@components/Footer/styles';
import { Products } from '@sections/Products';

import {
  HeroContainer,
  ContactUsPhone,
  HeroTextDiv,
  Title,
  PhoneAndParagraph,
  SecondPanel,
  SecondPanelTitle,
  SecondPanelText,
  ComplementaryPanel,
  HeroPicture,
  InsurancesSection,
  InsuranceDiv,
  InsuranceImage,
  InsuranceTextContainer,
  InsuranceTitle,
  InsurancesBackgroundPicture,
  LocationSection,
  LocationTextContainer,
  LocationTitle,
  LocationList,
  LocationItem,
  DownloadPDFSection,
  DownloadIcon,
  DownloadText,
  DownloadButton,
  HeroMobilePicture,
  Paragraph,
} from 'styles';

import ParachuteSection from '@sections/ParachutePanel';
import seoImage from '../../static/seo/seo_image.png';
import heroPNG from '../assets/images/hero.png';
import heroAvif from '../assets/images/hero.avif';
import heroMobilePNG from '../assets/images/heroMobile.png';
import heroMobileAvif from '../assets/images/heroMobile.avif';
import insuranceBackgroundJPG from '../assets/images/insuranceBackground.jpg';
import insuranceBackgroundAvif from '../assets/images/insuranceBackground.avif';

import medicaid from '../assets/images/medicaid.svg';
import medicare from '../assets/images/medicare.svg';
import privatePay from '../assets/images/privatePay.svg';
import seeFullList from '../assets/images/seeFullList.svg';
import download from '../assets/images/download.svg';

import DmePdf from '../assets/pdf/DME-WOF.pdf';

function Home() {
  return (
    <Layout>
      <Seoinfo
        siteName="Durable Medical Equipment"
        titlePage="Durable Medical Equipment | Life Home Care"
        description="Our products are designed with our clients' health & safety in mind and delivered by us with the same passion and commitment to excellence!"
        keywords="Home, Care, HomeCare, Senior, Compassionate, Care"
        type="website"
        url="mydme.life"
        imageFacebook={seoImage}
      />
      <HeroContainer>
        <HeroPicture>
          <source srcSet={heroAvif} type="image/avif" />
          <img src={heroPNG} alt="wheelchair" />
        </HeroPicture>
        <HeroMobilePicture>
          <source srcSet={heroMobileAvif} type="image/avif" />
          <img src={heroMobilePNG} alt="wheelchair" />
        </HeroMobilePicture>
        <InternalWrapper data-aos="fade">
          <HeroTextDiv>
            <Title>DURABLE MEDICAL EQUIPMENT</Title>
            <PhoneAndParagraph>
              <Paragraph>
                Flexible Solutions for Your Well-being: Sales and Rentals of Top-tier Medical
                Equipment
              </Paragraph>
              <ContactUsPhone href="tel:9738468441">(973) 846-8441</ContactUsPhone>
            </PhoneAndParagraph>
          </HeroTextDiv>
        </InternalWrapper>
      </HeroContainer>

      <SecondPanel>
        {/* eslint-disable-next-line */}
        <SecondPanelTitle>
          DISCOVER OUR DURABLE MEDICAL EQUIPMENT DISTRIBUTION PROGRAM!
        </SecondPanelTitle>
        {/* eslint-disable-next-line */}
        <SecondPanelText>
          We provide high-quality medical products designed for health and safety, delivered with
          our signature passion and commitment to excellence. Our extensive range ensures you get
          the reliable equipment you need, right when you need it.
        </SecondPanelText>
      </SecondPanel>
      <ComplementaryPanel>ITEMS WE COVER</ComplementaryPanel>
      <Products />
      <ParachuteSection />
      <ComplementaryPanel>PARTICIPATING INSURANCES</ComplementaryPanel>
      <InsurancesSection>
        <InsurancesBackgroundPicture>
          <source srcSet={insuranceBackgroundAvif} type="image/avif" />
          <img src={insuranceBackgroundJPG} alt="" />
        </InsurancesBackgroundPicture>
        <div>
          <InsuranceDiv>
            <InsuranceImage bgImage={medicaid} />
            <InsuranceTextContainer>
              <InsuranceTitle>Medicare</InsuranceTitle>
            </InsuranceTextContainer>
          </InsuranceDiv>
          <InsuranceDiv>
            <InsuranceImage bgImage={medicare} />
            <InsuranceTextContainer>
              <InsuranceTitle>Medicaid</InsuranceTitle>
            </InsuranceTextContainer>
          </InsuranceDiv>
          <InsuranceDiv>
            <InsuranceImage bgImage={privatePay} />
            <InsuranceTextContainer>
              <InsuranceTitle>Private pay</InsuranceTitle>
            </InsuranceTextContainer>
          </InsuranceDiv>
          <InsuranceDiv>
            <InsuranceImage bgImage={seeFullList} />
            <InsuranceTextContainer>
              <InsuranceTitle>See full list</InsuranceTitle>
            </InsuranceTextContainer>
          </InsuranceDiv>
        </div>
      </InsurancesSection>
      <LocationSection>
        <LocationTextContainer left>
          <LocationTitle left>AREAS COVERED</LocationTitle>
          <LocationList left>
            <LocationItem>Bergen</LocationItem>
            <LocationItem>Hudson</LocationItem>
            <LocationItem>Essex</LocationItem>
            <LocationItem>Morris</LocationItem>
            <LocationItem>Union</LocationItem>
            <LocationItem>Somerset</LocationItem>
            <LocationItem>Passaic</LocationItem>
          </LocationList>
        </LocationTextContainer>
        <LocationTextContainer>
          <LocationTitle>LIFE DME</LocationTitle>
          <LocationList>
            <LocationItem className="admin">DME Administrator: Randy Coste</LocationItem>
            <LocationItem className="location">
              {' '}
              70 South Orange Ave, Livingston, NJ 07039
            </LocationItem>
            <LocationItem className="phone">DME Dept: (973) 846-8441</LocationItem>
            <LocationItem className="phone">DME Admin; Randy C. (973) 577-4470</LocationItem>
          </LocationList>
        </LocationTextContainer>
      </LocationSection>
      <DownloadPDFSection>
        {/* eslint-disable-next-line */}
        <DownloadText>
          <DownloadIcon bgImage={download} />
          Download the DME Order Form here.
        </DownloadText>
        {/* eslint-disable-next-line */}
        <DownloadButton href={DmePdf} download>
          DOWNLOAD FORM
        </DownloadButton>
      </DownloadPDFSection>
    </Layout>
  );
}

export default Home;
