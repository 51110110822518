import styled from 'styled-components';

import { DESKTOP_1024, TABLET_992, TABLET_600, MOBILE_460, MOBILE_380 } from '@global/styles/sizes';

import { COLORS } from '@global/styles/colors';

export const ProductPanelContainer = styled.div`
  text-align: center;

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const ProductPanel = styled.div`
  margin: auto;
  min-height: 300px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: ${TABLET_992}px) {
    grid-template-columns: 100%;
    width: 100%;
    max-width: 100%;
  }
`;

export const ProductPiture = styled.picture<IGridProps>`
  grid-column-start: ${(props) => {
    const position = props.gridPosition;
    if (position === 'mobility' || position === 'client room' || position === 'miscellaneous') {
      return '2;';
    }
    if (position === 'bath safety' || position === 'respiratory') {
      return '1;';
    }
    return 'auto;';
  }};
  grid-row-start: 1;
  overflow: hidden;
  & > img,
  & > source {
    width: 100%;
    height: 101%;
    object-fit: cover;
  }
  @media (max-width: ${TABLET_992}px) {
    grid-row-start: 1;
    grid-column-start: 1;
  }
`;

export const ProductTextContainer = styled.div<IGridProps>`
  grid-column-start: ${(props) => {
    const position = props.gridPosition;
    if (position === 'mobility' || position === 'client room' || position === 'miscellaneous') {
      return '1;';
    }
    if (position === 'bath safety' || position === 'respiratory') {
      return '2;';
    }
    return 'auto;';
  }};
  background: transparent linear-gradient(270deg, #e01a73 0%, #e0281a 100%) 0% 0% no-repeat
    padding-box;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 51px;
  text-align: left;
  @media (max-width: 1100px) {
    padding: 40px;
  }
  @media (max-width: ${TABLET_992}px) {
    grid-row-start: 2;
    grid-column-start: 1;
  }
  @media (max-width: ${TABLET_600}px) {
    padding: 30px 20px;
  }
  @media (max-width: ${MOBILE_460}px) {
    padding: 25px 30px 45px 20px;
  }
`;

export const ProductTitle = styled.h3`
  padding-bottom: 9px;
  position: relative;
  font: normal 37px/49px 'Roboto';
  text-transform: uppercase;
  &::after {
    content: '';
    width: 80px;
    height: 4px;
    background: white;
    position: absolute;
    bottom: -3px;
    left: 0;
  }

  @media (max-width: ${TABLET_992}px) {
    &::before {
      width: 200px;
    }
  }
  @media (max-width: ${TABLET_600}px) {
    margin: 0;
    padding-bottom: 15px;
    font: normal 30px/39px 'Roboto';
    &::after {
      width: 150px;
    }
  }
  @media (max-width: ${MOBILE_380}px) {
    font: normal 26px/36px 'Roboto';
  }
`;

export const DropDownArrow = styled.img<IGridProps>`
  width: 30px;
  position: relative;
  right: -50px;
  top: -13px;
  display: none;

  @media (max-width: ${TABLET_992}px) {
    width: 38px;
    margin-left: 20px;
    display: inline-block;
    transition: 0.5s ease-in-out;
    transform-origin: center;
    transform: translate(-100%, 50%) rotate(${(props) => (props.isActive ? '-180deg' : '0deg')});
  }
  @media (max-width: ${MOBILE_380}px) {
    width: 30px;
  }
`;

export const ProductText = styled.p`
  padding-top: 22px;
  font: normal 18px/26px 'Roboto';
  @media (max-width: ${MOBILE_460}px) {
    padding-top: 25px;
    font: normal 14px/20px 'Roboto';
  }
`;

export const IconsSection = styled.div<IGridProps>`
  display: grid;
  margin: 20px 0 15px;
  ${(props) => {
    const number = props.numberProducts;
    if (number === '5') {
      return 'width: 100%; grid-template-columns: 1fr 1fr 1fr;';
    }
    if (number === '4') {
      return 'width: 80%; grid-template-columns: 1fr 1fr;';
    }
    return 'width: 100%; grid-template-columns: 1fr;';
  }};
  @media (max-width: 1100px) {
    width: 100%;
  }
  @media (max-width: ${TABLET_992}px) {
    ${(props) => (props.isActive ? 'height: 150px' : 'height: 0;')};
    transition: all 0.5s ease-in-out;
    overflow: hidden;
  }
  @media (max-width: ${MOBILE_460}px) {
    margin: 10px 0px 0px;
    ${(props) => (props.isActive ? 'height: 140px' : 'height: 0;')};
  }
  @media (max-width: ${MOBILE_380}px) {
    ${({ isActive, numberProducts }) =>
      // eslint-disable-next-line no-nested-ternary
      isActive
        ? numberProducts === '5'
          ? `height: 190px; padding-top: 0px;`
          : `height: 130px; padding-top: 0px;`
        : `height: 0; padding-top: 6px;`}
    ${(props) => {
      const number = props.numberProducts;
      if (number === '5') {
        return 'width: 100%; grid-template-columns: 1fr 1fr;';
      }
      if (number === '4') {
        return 'width: 100%; grid-template-columns: 1fr 1fr;';
      }
      return 'width: 100%; grid-template-columns: 1fr;';
    }};
    & > div {
      ${(props) => {
        const number = props.numberProducts;
        if (number === '5') {
          return 'width: 60px;';
        }
        if (number === '4') {
          return 'width: 150px;';
        }
        return 'width: 100%;';
      }};
    }
  }
`;

export const DMEProduct = styled.div`
  margin: 20px 0px;
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  @media (max-width: 1100px) {
    width: 80%;
  }
  @media (max-width: ${TABLET_992}px) {
    width: 100%;
  }
`;

export const DMEProductImage = styled.div<IIconProps>`
  height: 40px;
  background: ${(props) =>
    props.bgImage ? `url(${props.bgImage}) center/contain no-repeat` : 'none'};
  grid-column: 1 / 2;
  @media (max-width: ${MOBILE_460}px) {
    width: 30px;
    height: 30px;
  }
`;

export const DMEProductTextContainer = styled.div`
  padding-left: 18px;
  grid-column: 2 / 3;
  @media (max-width: ${DESKTOP_1024}px) {
    padding-left: 10px;
  }
  @media (max-width: ${MOBILE_460}px) {
    padding-left: 0px;
  }
`;

export const DMEProductTitle = styled.h4<ITextProps>`
  font: 500 18px/28px 'Roboto';
  color: ${COLORS.white};
  text-align: left;
  text-transform: capitalize;
  ${(props) => (props.longText ? 'width: 100px;' : 'width: auto;')};
  @media (max-width: ${DESKTOP_1024}px) {
    font: 500 16px/26px 'Roboto';
  }
  @media (max-width: ${MOBILE_460}px) {
    font: 500 14px/20px 'Roboto';
  }
`;

interface IIconProps {
  bgImage: any;
}

interface IGridProps {
  gridPosition?: string;
  numberProducts?: string;
  left?: boolean;
  isActive?: boolean;
}

interface ITextProps {
  longText?: string;
}
