import React from 'react';
import { Helmet } from 'react-helmet';
import { DOMAIN } from '../../config';
import { DMEStructuredData } from './schemaData';

const Seoinfo: React.FunctionComponent<ISeoinfoProps> = ({
  description,
  keywords,
  type,
  titlePage,
  url,
  imageFacebook,
  siteName,
}) => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>{titlePage}</title>
    <meta name="robots" content="index,follow" />
    <meta name="description" content={description} />
    <meta name="keywords" content={keywords} />
    <meta property="og:type" content={type} />
    <meta property="og:title" content={titlePage} />
    <meta property="og:url" content={`${DOMAIN}${url}`} />
    <meta property="og:image" content={`${DOMAIN}${imageFacebook}`} />
    <meta property="og:description" content={description} />
    <meta property="og:site_name" content={siteName} />
    <meta
      name="twitter:title"
      content="Compassionate Care in the Comfort of Your Home | Durable Medical Equipment"
    />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@lifehomecare" />
    <meta name="twitter:creator" content="@lifehomecare" />
    <meta property="og:url" content="URL" />
    <meta property="og:locale" content="en_US" />
    <meta property="og:site_name" content="Durable Medical Equipment" />
    <script type="application/ld+json">{JSON.stringify(DMEStructuredData)}</script>
  </Helmet>
);
export default Seoinfo;

interface ISeoinfoProps {
  description: string;
  keywords: string;
  type: string;
  titlePage: string;
  url: string;
  imageFacebook: string;
  siteName: string;
}
