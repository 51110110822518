import React from 'react';
import { Helmet } from 'react-helmet';
import { GlobalStyles } from '@global/styles/globalStyles';
import Topbar from '../Topbar';
import Footer from '../Footer';

import { LayoutContainer } from './styles';

const Layout = ({ children, className }: ILayoutProps) => {
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en-US' }} />
      <GlobalStyles />
      <LayoutContainer className={className ? `layout ${className}` : 'layout '}>
        <Topbar />
        {children}
        <Footer />
      </LayoutContainer>
    </>
  );
};

export default Layout;

interface ILayoutProps {
  children?: React.ReactNode;
  className?: string;
}

Layout.defaultProps = {
  children: undefined,
  className: undefined,
};
