export const PATHS = {
  INDEX: '/',
};

export const SERVICES = {
  HOME_THERAPY: 'https://www.hometherapy.life/',
  HOME_CARE: 'https://homecare.life/',
  RIDE: 'https://ride.life/',
  DME: 'http://www.mydme.life/',
  HOSPICE: 'https://hospicecare.life/',
};

export const SOCIAL = {
  FACEBOOK: 'https://www.facebook.com/lifehomecarenewjersey',
  INSTAGRAM: 'https://www.instagram.com/lifehomecare.nj/',
  LINKEDIN: 'https://www.linkedin.com/company/lifehomecarenewjersey/',
};
