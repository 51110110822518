import styled from 'styled-components';

import {
  DESKTOP_1200,
  DESKTOP_1024,
  TABLET_1023,
  TABLET_992,
  TABLET_800,
  TABLET_768,
  TABLET_740,
  TABLET_600,
  MOBILE_460,
  MOBILE_380,
  MOBILE_320,
} from '@global/styles/sizes';

import { COLORS } from '@global/styles/colors';

import admin from '../assets/images/admin.svg';
import location from '../assets/images/location.svg';
import phoneCall from '../assets/images/phoneCall.svg';

export const HeroContainer = styled.div`
  height: 800px;
  margin-top: 90px;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @media (max-width: ${DESKTOP_1200}px) {
    height: 650px;
  }
  @media (max-width: ${TABLET_1023}px) {
    height: 500px;
  }
  @media (max-width: ${TABLET_800}px) {
    height: 450px;
  }
  @media (max-width: ${TABLET_740}px) {
    height: 590px;
    margin: 70px 0 250px;
  }
  @media (max-width: ${TABLET_600}px) {
    height: 500px;
  }
  @media (max-width: 500px) {
    height: 404px;
  }
  @media (max-width: ${MOBILE_460}px) {
    height: 360px;
  }
  @media (max-width: ${MOBILE_380}px) {
    height: 300px;
  }
  @media (max-width: ${MOBILE_320}px) {
    height: 255px;
  }
`;

export const Paragraph = styled.p`
  margin-top: 5px;
  color: #464748;
  font: normal normal normal 26px/30px Roboto;
  width: 41%;
  position: relative;
  z-index: 2;
  margin-bottom: 40px;

  @media (max-width: ${TABLET_992}px) {
    width: 40%;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
  @media (max-width: ${TABLET_768}px) {
    width: 100%;
    font: normal normal normal 18px/26px Roboto;
    margin: 0 0 20px 0;
    text-align: left;
    align-items: center;
  }
`;

export const HeroPicture = styled.picture`
  & > img,
  & > picture {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    @media (max-width: 1600px) {
      object-position: 80%;
    }
    @media (max-width: ${DESKTOP_1200}px) {
      object-position: 83%;
    }
    @media (max-width: ${TABLET_1023}px) {
      object-position: 90%;
    }
    @media (max-width: ${TABLET_800}px) {
      object-position: 80%;
    }
  }
  @media (max-width: ${TABLET_740}px) {
    display: none;
  }
`;

export const HeroMobilePicture = styled.picture`
  display: none;
  & > img,
  & > picture {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }
  @media (max-width: ${TABLET_740}px) {
    display: block;
  }
`;

export const HeroTextDiv = styled.div`
  @media (max-width: ${TABLET_740}px) {
    position: relative;
    top: 415px;
  }
  @media (max-width: ${TABLET_600}px) {
    top: 370px;
  }
  @media (max-width: 500px) {
    top: 322px;
  }
  @media (max-width: ${MOBILE_460}px) {
    padding: 20px;
    top: 300px;
  }
  @media (max-width: ${MOBILE_380}px) {
    top: 270px;
  }
  @media (max-width: ${MOBILE_320}px) {
    top: 245px;
  }
`;

export const Title = styled.h1`
  width: 50%;
  margin: 0px 0 25px;
  color: ${COLORS.redTitle};
  text-align: left;
  font: normal 57px/60px 'Roboto';
  position: relative;
  z-index: 2;
  @media (max-width: ${DESKTOP_1200}px) {
    width: 60%;
    font: normal 48px/56px 'Roboto';
  }
  @media (max-width: ${TABLET_1023}px) {
    width: 45%;
    font: normal 35px/48px 'Roboto';
  }
  @media (max-width: ${TABLET_768}px) {
    width: 58%;
    font: normal 35px/48px 'Roboto';
  }
  @media (max-width: ${TABLET_740}px) {
    width: 380px;
    margin: 15px 0px 18px;
    font: normal 42px/52px 'Roboto';
  }
  @media (max-width: ${MOBILE_460}px) {
    font: normal 38px/48px 'Roboto';
  }
  @media (max-width: ${MOBILE_380}px) {
    width: 100%;
    font: normal 30px/36px 'Roboto';
  }
`;

export const PhoneAndParagraph = styled.div`
  @media (max-width: ${TABLET_740}px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ContactUsPhone = styled.a`
  width: fit-content;
  padding: 14px 45px;
  border-radius: 25px;
  font: 400 18px/22px 'Roboto';
  color: ${COLORS.white};
  background-color: ${COLORS.vermilion};
  border: 0;
  align-self: left;
  word-wrap: break-word;
  position: relative;
  z-index: 2;
  margin-bottom: 10px;
  &:hover {
    filter: grayscale(50%);
  }
`;

export const SecondPanel = styled.div`
  width: 100%;
  padding: 40px;
  background: transparent linear-gradient(270deg, #e01a73 0%, #e0281a 100%) 0% 0% no-repeat
    padding-box;
  @media (max-width: ${DESKTOP_1200}px) {
    padding: 70px 2%;
  }
  @media (max-width: ${TABLET_740}px) {
    padding: 50px 20px;
  }
`;

export const SecondPanelTitle = styled.div`
  margin-bottom: 15px;
  text-align: center;
  font: bold 37px/49px 'Roboto';
  color: ${COLORS.white};
  @media (max-width: ${DESKTOP_1024}px) {
    font: bold 32px/42px 'Roboto';
  }
  @media (max-width: ${TABLET_740}px) {
    text-align: left;
  }
  @media (max-width: ${TABLET_600}px) {
    font: bold 25px/33px 'Roboto';
  }
`;

export const SecondPanelText = styled.div`
  text-align: center;
  font: 500 20px/28px 'Roboto';
  color: ${COLORS.white};
  @media (max-width: ${DESKTOP_1024}px) {
    font: 500 18px/24px 'Roboto';
  }
  @media (max-width: ${TABLET_740}px) {
    text-align: left;
  }
  @media (max-width: ${TABLET_600}px) {
    font: 400 18px/24px 'Roboto';
  }
`;

export const ComplementaryPanel = styled.div`
  padding: 70px 0;
  text-align: center;
  font: 500 37px/49px 'Roboto';
  color: ${COLORS.vermilion};
  background-color: ${COLORS.white};

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    max-width: 100%;
  }
  @media (max-width: ${TABLET_740}px) {
    padding: 50px 20px;
    font: 500 30px/39px 'Roboto';
    text-align: left;
  }
  @media (max-width: ${MOBILE_460}px) {
    max-width: 400px;
  }
`;

export const InsurancesSection = styled.div`
  position: relative;
  @media (max-width: ${TABLET_1023}px) {
    padding: 20px 0;
  }
  @media (max-width: ${TABLET_600}px) {
    padding: 0;
  }
  & > div {
    max-width: 1280px;
    min-height: 220px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    @media (max-width: ${TABLET_1023}px) {
      max-width: 800px;
      justify-items: center;
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: ${TABLET_600}px) {
      min-height: 200px;
      padding: 0;
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: ${MOBILE_380}px) {
      padding: 10px;
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

export const InsurancesBackgroundPicture = styled.picture`
  & > img,
  & > picture {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }
`;

export const InsuranceDiv = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 80px 1fr;
  align-items: center;
  padding: 20px;
  @media (max-width: ${DESKTOP_1200}px) {
    padding: 10px;
  }
  @media (max-width: ${TABLET_992}px) {
    width: 250px;
  }
  @media (max-width: ${TABLET_600}px) {
    width: 100px;
    height: 100px;
    padding: 0px;
    align-self: center;
    grid-template-columns: 100%;
    grid-template-rows: 1fr 1fr;
  }
  @media (max-width: ${MOBILE_460}px) {
    width: auto;
  }
`;

export const InsuranceImage = styled.div<IIconProps>`
  max-height: 80px;
  min-height: 80px;
  background: ${(props) =>
    props.bgImage ? `url(${props.bgImage}) center/contain no-repeat` : 'none'};
  grid-column: 1 / 2;
  @media (max-width: ${TABLET_600}px) {
    max-height: 40px;
    min-height: 40px;
  }
  @media (max-width: ${MOBILE_380}px) {
    max-height: 30px;
    min-height: 30px;
  }
`;

export const InsuranceTextContainer = styled.div`
  grid-column: 2 / 3;
  @media (max-width: ${DESKTOP_1024}px) {
    width: 100px;
  }
  @media (max-width: ${TABLET_600}px) {
    width: auto;
  }
  @media (max-width: ${TABLET_600}px) {
    grid-column: 1 / 2;
  }
`;

export const InsuranceTitle = styled.h4`
  margin-left: 20px;
  font: 500 24px/28px 'Roboto';
  color: ${COLORS.vermilion};
  text-align: left;
  text-transform: capitalize;
  white-space: nowrap;
  @media (max-width: ${TABLET_600}px) {
    margin: 10px 0 0;
    text-align: center;
    font: 500 18px/24px 'Roboto';
    white-space: break-spaces;
  }
  @media (max-width: ${MOBILE_460}px) {
    font: 500 16px/20px 'Roboto';
    margin: 0;
  }
`;

export const LocationSection = styled.div`
  margin: auto;
  min-height: 450px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: ${TABLET_992}px) {
    grid-template-columns: 100%;
    width: 100%;
    max-width: 100%;
  }
`;

export const LocationTextContainer = styled.div<IGridProps>`
  ${(props) =>
    props.left
      ? `background: transparent linear-gradient(270deg, #e01a73 0%, #e0281a 100%) 0% 0% no-repeat padding-box;
      color: white;`
      : `background-color: white; color: #464748;`};

  display: flex;
  flex-direction: column;
  padding: 70px 60px;
  text-align: left;

  @media (max-width: ${TABLET_600}px) {
    padding: 30px 20px;
  }
`;

export const LocationTitle = styled.h3<IGridProps>`
  padding-bottom: 10px;
  margin: 0 0 20px;
  position: relative;
  font: normal 37px/49px 'Roboto';
  text-transform: uppercase;
  ${(props) =>
    props.left
      ? `&::before {
      content: '';
      width: 80px;
      height: 4px;
      background: white;
      position: absolute;
      bottom: 0;
      left: 0;
    }`
      : `&::before {
        content: '';
      width: 80px;
      height: 4px;
      background: ${COLORS.vermilion};
      position: absolute;
      bottom: 0;
      left: 0;}`};

  @media (max-width: ${TABLET_600}px) {
    font: normal 30px/39px 'Roboto';
    ${(props) => (props.left ? `&::before { width: 154px;}` : `&::before {width: 152px;}`)};
  }
`;

export const LocationList = styled.ul<IGridProps>`
  display: flex;
  flex-direction: column;

  @media (max-width: ${TABLET_600}px) {
    ${(props) => (props.left ? `height: 152px;` : `height: auto;`)};
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    & > li {
      font: normal 14px/28px 'Roboto';
    }
  }
`;

export const LocationItem = styled.li`
  margin: 5px 0;
  list-style-type: disc;
  list-style-position: inside;
  font: normal 20px/32px 'Roboto';
  &.location {
    list-style-image: url(${location});
  }
  &.phone {
    list-style-image: url(${phoneCall});
  }
  &.admin {
    list-style-image: url(${admin});
  }
`;

export const DownloadPDFSection = styled.div`
  height: 190px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: ${COLORS.vermilion};
  @media (max-width: ${TABLET_992}px) {
    height: auto;
    flex-direction: column;
    justify-content: center;
    padding: 50px 20px;
  }
`;

export const DownloadIcon = styled.div<IIconProps>`
  width: 40px;
  height: 40px;
  margin-right: 25px;
  background: ${(props) =>
    props.bgImage ? `url(${props.bgImage}) center/contain no-repeat` : 'none'};
  @media (max-width: ${TABLET_600}px) {
    height: auto;
    margin-right: 20px;
  }
`;

export const DownloadText = styled.p`
  margin: 5px 0;
  color: ${COLORS.white};
  font: normal 30px/40px 'Roboto';
  display: flex;
  justify-content: center;
  @media (max-width: ${TABLET_992}px) {
    line-height: 36px;
    margin: 0 0 25px;
  }
  @media (max-width: ${TABLET_600}px) {
    width: 370px;
  }
  @media (max-width: ${MOBILE_460}px) {
    width: 90%;
    font: normal 30px/36px 'Roboto';
  }
  @media (max-width: ${MOBILE_380}px) {
    font: normal 28px/34px 'Roboto';
  }
`;

export const DownloadButton = styled.a`
  background-color: ${COLORS.white};
  color: ${COLORS.vermilion};
  font: normal 18px/24px 'Roboto';
  padding: 12px 62px;
  border-radius: 25px;
`;

interface IIconProps {
  bgImage: any;
}

interface IGridProps {
  gridPosition?: string;
  numberProducts?: string;
  left?: boolean;
  isActive?: boolean;
}
