import { DESKTOP_1200, MOBILE_380, MOBILE_460, TABLET_740, TABLET_800 } from 'global/styles/sizes';

import { COLORS } from 'global/styles/colors';
import { Link } from 'gatsby';
import styled from 'styled-components';

export const FooterContainer = styled.div`
  width: 100%;
  min-height: 280px;
  background-color: ${COLORS.darkGray};
  padding: 30px;

  @media (max-width: ${DESKTOP_1200}px) {
    padding: 30px 2% 30px 2%;
  }

  @media (max-width: 880px) {
    padding: 30px;
  }
`;

export const InternalWrapper = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: auto;
  @media (max-width: ${MOBILE_460}px) {
    width: 100%;
  }
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${TABLET_800}px) {
    flex-direction: column;
  }
`;

export const LogosDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  @media (max-width: ${TABLET_800}px) {
    margin: 0;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  margin-top: 20px;

  @media (max-width: ${TABLET_800}px) {
    width: 40%;
    justify-content: flex-end;
    margin: 0;
  }
  @media (max-width: 380px) {
    margin-left: 7px;
  }
`;

export const SocialMediaIcon = styled.img`
  width: 45px;
  height: 45px;
  margin-right: 11px;
  transition: 0.1s all;
  :hover,
  :focus {
    transition: 0.1s all;
    filter: gray;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
  }
  @media (max-width: ${TABLET_800}px) {
    width: 31px;
    height: 31px;
  }

  @media (max-width: 380px) {
    margin-right: 7px;
  }
`;

export const Logo = styled.img`
  width: 135px;
  height: 65px;
  @media (max-width: ${MOBILE_460}px) {
    width: 100px;
  }
`;

export const OtherPages = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 37px;

  @media (max-width: ${TABLET_800}px) {
    justify-content: center;
  }
`;

export const ItemsDiv = styled.div`
  display: flex;
  justify-content: right;

  @media (max-width: 600px) {
    flex-direction: column;
  }
  @media (max-width: ${MOBILE_460}px) {
    justify-content: center;
  }
`;

export const SectionDiv = styled.div`
  max-width: 250px;
  min-width: 150px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${DESKTOP_1200}px) {
    margin-right: 10px;
  }
  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    align-items: flex-start;
    margin-top: 30px;
  }
  @media (max-width: ${MOBILE_380}px) {
    margin: 30px 0 0;
  }
`;

export const Title = styled.div`
  margin-bottom: 15px;
  font: 400 18px/22px 'Roboto';
  color: ${COLORS.white};
  @media (max-width: ${MOBILE_460}px) {
    margin-bottom: 10px;
  }
`;

export const Text = styled.div`
  font: 400 15px/30px 'Roboto';
  color: ${COLORS.white};

  @media (max-width: ${TABLET_740}px) {
    font: 400 15px/26px 'Roboto';
  }
`;

export const ListItem = styled.li`
  font: 300 15px/32px 'Roboto';
  color: ${COLORS.white};
  @media (max-width: ${MOBILE_460}px) {
    font: 300 14px/28px 'Roboto';
  }
`;

export const CustomLink = styled.a`
  color: ${COLORS.white};
  line-height: 2px;
`;

export const CustomLinkHome = styled(Link)`
  color: ${COLORS.white};
  line-height: 2px;
`;

export const ContactDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;

  a {
    display: flex;
  }
`;

export const Disclaimer = styled.p`
  width: 100%;
  max-width: 1009px;
  margin: 10px auto 0;
  font: 400 15px/20px 'Roboto';
  color: ${COLORS.white};

  @media (max-width: 740px) {
    font-size: 14px;
  }
`;
