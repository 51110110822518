export const COLORS = {
  black: '#000000',
  darkGray: '#414141',
  gray: '#727272',
  grayTopbarItem: '#707070',
  lightPurple: '#e2e4e8',
  melon: '#ecd6c7',
  purple: '#7971FA',
  red: '#FF0000',
  redTitle: '#e0281a',
  transparent: '#40404000',
  vermilion: '#ec4747',
  white: '#FFFFFF',
};
