/* eslint-disable camelcase */
const get_API_URI = () => {
  if (process.env.API_URI) {
    return process.env.API_URI;
  }
  return 'http://localhost:5000';
};
export const API_URI = get_API_URI();

const get_BLOG_URI = () => {
  if (process.env.BLOG_URI) {
    return process.env.BLOG_URI;
  }
  return 'https://www.example.com';
};
export const BLOG_URI = get_BLOG_URI();

const get_DOMAIN = () => {
  if (process.env.DOMAIN) {
    return process.env.DOMAIN;
  }
  return 'http://localhost:8001';
};
export const DOMAIN = get_DOMAIN();
