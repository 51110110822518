import Life from 'assets/images/LIFEDME.svg';
import { Link } from 'gatsby';
import React from 'react';
import { PATHS, SERVICES, SOCIAL } from '../../global/constants/urls';
import {
  ContactDiv,
  CustomLink,
  CustomLinkHome,
  Disclaimer,
  FooterContainer,
  FooterContent,
  InternalWrapper,
  ItemsDiv,
  ListItem,
  Logo,
  LogosDiv,
  SectionDiv,
  SocialMedia,
  SocialMediaIcon,
  Text,
  Title,
} from './styles';
import facebookRed from './assets/facebookRed.svg';
import instagramRed from './assets/instagramRed.svg';
import linkedInRed from './assets/linkedInRed.svg';

const Footer = () => {
  return (
    <FooterContainer>
      <InternalWrapper>
        <FooterContent>
          <LogosDiv>
            <Link to={PATHS.INDEX}>
              <Logo src={Life} alt="Life logo" />
            </Link>
            <SocialMedia>
              <a href={SOCIAL.FACEBOOK} target="__blank">
                <SocialMediaIcon src={facebookRed} alt="facebook" />
              </a>
              <a href={SOCIAL.LINKEDIN} target="__blank">
                <SocialMediaIcon src={linkedInRed} alt="linkedin" />
              </a>
              <a href={SOCIAL.INSTAGRAM} target="__blank">
                <SocialMediaIcon src={instagramRed} alt="instagram" />
              </a>
            </SocialMedia>
          </LogosDiv>
          <ItemsDiv>
            <SectionDiv>
              <Title>Our Services</Title>
              <ul>
                <ListItem>
                  <CustomLink href={SERVICES.HOME_CARE}>Home Care</CustomLink>
                </ListItem>
                <ListItem>
                  <CustomLink href={SERVICES.HOME_THERAPY}>Home Therapy</CustomLink>
                </ListItem>
                <ListItem>
                  <CustomLink href={SERVICES.RIDE}>Ride</CustomLink>
                </ListItem>
                <ListItem>
                  <CustomLinkHome to={SERVICES.HOSPICE}>Hospice</CustomLinkHome>
                </ListItem>
              </ul>
            </SectionDiv>
            <SectionDiv>
              <Title>Coverage Area</Title>
              <ul>
                <ListItem>Essex</ListItem>
                <ListItem>Morris</ListItem>
                <ListItem>Union</ListItem>
                <ListItem>Somerset</ListItem>
                <ListItem>Passaic</ListItem>
              </ul>
            </SectionDiv>
            <SectionDiv>
              <Title>Contact</Title>
              <ContactDiv>
                <a
                  href="https://g.page/Life-home-care-senior-new-jersey?share"
                  target="_blank"
                  rel="noopener nofollow noreferrer"
                >
                  <Text>
                    70 South Orange Ave, Suite 220, <br /> Livingston, NJ 07039
                  </Text>
                </a>
              </ContactDiv>
              <ContactDiv>
                <a href="tel:9738468441">
                  <Text>(973) 846-8441</Text>
                </a>
              </ContactDiv>
            </SectionDiv>
          </ItemsDiv>
        </FooterContent>
        <FooterContent>
          <Disclaimer>
            Life Ride, Life Home Care, Life DME, Life Home Therapy, Life Hospice, Life Health Group
            logos are all Registered in U.S. Patent and Trademark Oﬃce
          </Disclaimer>
        </FooterContent>
      </InternalWrapper>
    </FooterContainer>
  );
};

export default Footer;
