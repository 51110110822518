import React from 'react';
import { Link } from 'gatsby';

import ArrowDown from 'assets/images/arrow-down.svg';
import lifeLogo from './assets/LIFEDME.svg';
import hamburguerIcon from './assets/hamburguer-menu-icon.svg';

import { PATHS, SERVICES } from '../../global/constants/urls';

import {
  Container,
  MenuContainer,
  MenuDesktop,
  ContactUsPhone,
  MenuDesktopBrand,
  MenuDesktopList,
  MenuDesktopItem,
  MenuDesktopServicesItem,
  DropdownContent,
  MenuMobile,
  MenuMobileButton,
  HamburguerIcon,
} from './styles';

const Topbar = () => {
  const [open, setOpen] = React.useState(false);

  return (
    <MenuContainer>
      <MenuDesktop>
        <Container>
          <MenuDesktopBrand>
            <Link to={PATHS.INDEX}>
              <img src={lifeLogo} alt="Life Logo" />
            </Link>
          </MenuDesktopBrand>
          <MenuDesktopList>
            <MenuDesktopServicesItem>
              Other services <img src={ArrowDown} alt="arrow down" />
              <div>
                <a href={SERVICES.HOME_CARE}>home care</a>
                <a href={SERVICES.HOME_THERAPY}>home therapy</a>
                <a href={SERVICES.RIDE}>ride</a>
                <a href={SERVICES.HOSPICE}>hospice</a>
              </div>
            </MenuDesktopServicesItem>
            <MenuDesktopItem>
              <ContactUsPhone href="tel:9738468441">(973) 846-8441</ContactUsPhone>
            </MenuDesktopItem>
          </MenuDesktopList>
        </Container>
      </MenuDesktop>

      {/* MOBILE */}

      <MenuMobile>
        <Container>
          <MenuMobileButton>
            <HamburguerIcon
              onClick={() => setOpen(!open)}
              src={hamburguerIcon}
              alt="hamburguer menu"
            />
            <DropdownContent isOpen={open}>
              <ul>
                <a href={SERVICES.HOME_CARE}>home care</a>
                <a href={SERVICES.HOME_THERAPY}>home therapy</a>
                <a href={SERVICES.RIDE}>ride</a>
                <a href={SERVICES.HOSPICE}>hospice</a>
                <a href="tel:9738468441">Contact</a>
              </ul>
            </DropdownContent>
          </MenuMobileButton>
          <MenuDesktopBrand>
            <Link to={PATHS.INDEX}>
              <img src={lifeLogo} alt="Life Logo" />
            </Link>
          </MenuDesktopBrand>
        </Container>
      </MenuMobile>
    </MenuContainer>
  );
};

export default Topbar;
