/* mobility */
import mobilityPNG from '../../assets/images/mobility.png';
import mobilityAvif from '../../assets/images/mobility.avif';

import canes from '../../assets/images/canes.svg';
import crutches from '../../assets/images/crutches.svg';
import walkers from '../../assets/images/walkers.svg';
import wheelchairs from '../../assets/images/wheelchairs.svg';
import rollators from '../../assets/images/rollators.svg';

/* bath safety */
import bathSafetyPNG from '../../assets/images/bathSafety.png';
import bathSafetyAvif from '../../assets/images/bathSafety.avif';

import commodes from '../../assets/images/commodes.svg';
import toiletSeats from '../../assets/images/toiletSeats.svg';
import showerBenches from '../../assets/images/showerBenches.svg';
import bathroomRails from '../../assets/images/bathroomRails.svg';

/* client room */
import clientRoomPNG from '../../assets/images/clientRoom.png';
import clientRoomAvif from '../../assets/images/clientRoom.avif';

import hospitalBeds from '../../assets/images/hospitalBeds.svg';
import supportSurfaces from '../../assets/images/supportSurfaces.svg';
import clientLifts from '../../assets/images/clientLifts.svg';
import trapezeBars from '../../assets/images/trapezeBars.svg';

/* respiratory */
import respiratoryPNG from '../../assets/images/respiratory.png';
import respiratoryAvif from '../../assets/images/respiratory.avif';

import oxygenConcentrator from '../../assets/images/oxygenConcentrator.svg';
import homefillSystem from '../../assets/images/homefillSystem.svg';
import biPap from '../../assets/images/biPap.svg';
import cPap from '../../assets/images/cPap.svg';

/* miscellaneous */
import miscellaneousPNG from '../../assets/images/miscellaneous.png';
import miscellaneousAvif from '../../assets/images/miscellaneous.avif';

import walkerAccessories from '../../assets/images/walkerAccessories.svg';
import dailyAccessories from '../../assets/images/dailyAccessories.svg';

export const services = [
  {
    typeOfProduct: 'mobility',
    alternativeText: 'walker',
    /* eslint-disable-next-line */
    description: 'Our goal is to promote independence for our clients by providing compassionate care in the comfort of their homes.',
    imgPNG: mobilityPNG,
    imgAvif: mobilityAvif,
    products: [
      {
        img: canes,
        title: 'Canes',
      },
      {
        img: crutches,
        title: 'crutches',
      },
      {
        img: wheelchairs,
        title: 'wheelchairs',
      },
      {
        img: walkers,
        title: 'walkers',
      },
      {
        img: rollators,
        title: 'rollators',
      },
    ],
  },
  {
    typeOfProduct: 'bath safety',
    alternativeText: 'grab bar for the bathroom',
    imgPNG: bathSafetyPNG,
    imgAvif: bathSafetyAvif,
    products: [
      {
        img: commodes,
        title: 'Commodes',
      },
      {
        img: toiletSeats,
        title: 'Toilet seats',
      },
      {
        img: showerBenches,
        title: 'Shower benches',
      },
      {
        img: bathroomRails,
        title: 'Bathroom rails',
      },
    ],
  },
  {
    typeOfProduct: 'client room',
    alternativeText: 'bed for seniors',
    imgPNG: clientRoomPNG,
    imgAvif: clientRoomAvif,
    products: [
      {
        img: hospitalBeds,
        title: 'Hospital beds',
      },
      {
        img: supportSurfaces,
        title: 'Support surfaces',
      },
      {
        img: clientLifts,
        title: 'Client lifts (Hoyer lift)',
      },
      {
        img: trapezeBars,
        title: 'Trapeze bars',
      },
    ],
  },
  {
    typeOfProduct: 'respiratory',
    alternativeText: 'cpap machine',
    imgPNG: respiratoryPNG,
    imgAvif: respiratoryAvif,
    products: [
      {
        img: oxygenConcentrator,
        title: 'Oxygen concentrator',
      },
      {
        img: homefillSystem,
        title: 'Homefill system',
      },
      {
        img: biPap,
        title: 'BiPap',
      },
      {
        img: cPap,
        title: 'CPap',
      },
    ],
  },
  {
    typeOfProduct: 'miscellaneous',
    alternativeText: 'wheelchair',
    imgPNG: miscellaneousPNG,
    imgAvif: miscellaneousAvif,
    products: [
      {
        img: walkerAccessories,
        title: 'Walker accessories',
      },
      {
        img: dailyAccessories,
        title: 'Activity of daily living accessories',
      },
    ],
  },
];
