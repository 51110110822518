import styled from 'styled-components';
import { COLORS } from '@global/styles/colors';
import { TABLET_992, TABLET_768 } from '@global/styles/sizes';

export const ParachuteContainer = styled.div`
  width: 100%;
  padding: 11px 35px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.redTitle};
  gap: 20px;

  @media (max-width: ${TABLET_992}px) {
    padding: 66px 21px 11px;
    gap: 0;
    flex-direction: column;
    text-align: center;
  }
`;

export const Image = styled.img`
  width: 436px;
  height: 244px;

  @media (max-width: ${TABLET_992}px) {
    width: 374px;
    height: 200px;
  }
`;

export const Text = styled.div`
  max-width: 543px;
  font: normal normal 500 34px/40px Roboto;
  color: ${COLORS.white};

  @media (max-width: ${TABLET_768}px) {
    font-size: 34px;
    line-height: 38px;
  }
`;
