import React from 'react';
import Logo from './assets/logo-parachute.png';
import { ParachuteContainer, Text, Image } from './styles';

const ParachuteSection = () => {
  return (
    <ParachuteContainer>
      <Text>We are on Parachute Health Listed as Life DME New Jersey</Text>
      <Image src={Logo} />
    </ParachuteContainer>
  );
};

export default ParachuteSection;
